import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    ActivatePersonContactInput,
    AssignPersonToFolderInput,
    CompensationGridObject,
    CreateEpicrisisInput,
    CreateFolderMedicationInput,
    CreatePersonContactInput,
    CreatePersonInput,
    CreateVoucherInput,
    DeactivatePersonContactInput,
    DeleteEpicrisisInput,
    DeleteFolderMedicationInput,
    DeletePersonContactInput,
    DiagnosisGridObject,
    EpicrisisGridObject,
    EpicrisisObject,
    FolderGridObject,
    FolderMedicationGridObject,
    FolderMedicationObject,
    FolderObject,
    GetFolderByPersonInput,
    GetFolderInput,
    GetPersonInput,
    GetVoucherInput,
    MarkPersonContactAsPrimaryInput,
    MedicationGridObject,
    OkObject,
    PersonContactGridObject,
    PersonGridObject,
    PersonObject,
    SetContactPositionInput,
    UnmarkPrimaryPersonContactInput,
    UpdateEpicrisisInput,
    UpdateFolderMedicationInput,
    UpdateInsurancesInput,
    UpdatePersonContactInput,
    UpdatePersonInput,
    UpdateVoucherInput,
    VoucherGridObject,
    VoucherObject,
    VoucherPrescribedMedicalProcedureCreateInput,
    VoucherPrescribedMedicalProcedureDeleteInput,
    VoucherPrescribedMedicalProcedureGridObject,
    VoucherPrescribedMedicalProcedureUpdateInput,
} from '@it2go/types';
import { filterFrag } from './types/filter.types';
import {
    compensationGridItemFrag,
    diagnosisGridItemFrag,
    folderMedicationGridItemFrag,
    medicationGridItemFrag,
} from './types/medication.types';
import {
    epicrisisGridItemFrag,
    personContactGridItemFrag,
    personFrag,
    personGridItemFrag,
    serviceRecordGridItemFrag,
} from './types/person.types';
import { voucherFrag, voucherGridItemFrag } from './types/voucher.types';
import {
    epicrisisFrag,
    folderFrag,
    folderGridItemFrag,
    folderMedicationFrag,
    folderPersonFrag,
} from './types/folder.types';
import { GqlService } from '../modules/content/service/gql.service';
import { voucherPrescribedMedicalProcedureFrag } from '../modules/content/service/types/voucher.types';

@Injectable()
export class PersonService extends GqlService {
    getPersons(input: GridInput | null) {
        return this.query<PersonGridObject>(
            `
                query($input: GridInput) {
                    person {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${personGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getPerson(input: GetPersonInput) {
        return this.query<PersonObject>(`
                query($input: GetPersonInput!) {
                    person {
                        get(input: $input) {
                            ${personFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    createPerson(input: CreatePersonInput) {
        return this.mutate<PersonObject>(
            `
                mutation($input: CreatePersonInput!) {
                    person {
                        create(input: $input) {
                            ${personFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updatePerson(input: UpdatePersonInput) {
        return this.mutate<PersonObject>(
            `
                mutation($input: UpdatePersonInput!) {
                    person {
                        update(input: $input) {
                            ${personFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    assignPersonService(input: AssignPersonToFolderInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: AssignPersonToFolderInput!) {
                    person {
                        assignService(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getEpicrises(input: GridInput | null) {
        return this.query<EpicrisisGridObject>(
            `
                query($input: GridInput) {
                    epicrisis {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${epicrisisGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createEpicrisis(input: CreateEpicrisisInput) {
        return this.mutate<EpicrisisObject>(
            `
                mutation($input: CreateEpicrisisInput!) {
                    epicrisis {
                        create(input: $input) {
                            ${epicrisisFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateEpicrisis(input: UpdateEpicrisisInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdateEpicrisisInput!) {
                    epicrisis {
                        update(input: $input) {
                            ${epicrisisFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteEpicrisis(input: DeleteEpicrisisInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DeleteEpicrisisInput!) {
                    epicrisis {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFolderMedications(input: GridInput | null) {
        return this.query<FolderMedicationGridObject>(
            `
                query($input: GridInput) {
                    folderMedication {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderMedicationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createFolderMedication(input: CreateFolderMedicationInput) {
        return this.mutate<FolderMedicationObject>(
            `
                mutation($input: CreateFolderMedicationInput!) {
                    folderMedication {
                        create(input: $input) {
                            ${folderMedicationFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteFolderMedication(input: DeleteFolderMedicationInput) {
        return this.mutate(
            `
            mutation($input: DeleteFolderMedicationInput!) {
                folderMedication {
                    delete(input: $input) {
                        ok
                    }
                }
            }
        `,
            { input },
        );
    }

    updateFolderMedication(input: UpdateFolderMedicationInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdateFolderMedicationInput!) {
                    folderMedication {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getPersonContacts(input: GridInput | null) {
        return this.query<PersonContactGridObject>(
            `
                query($input: GridInput) {
                    personContact {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${personContactGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    reorderPersonContactPosition(input: SetContactPositionInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: SetContactPositionInput!) {
                    personContact {
                        reorder(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    createPersonContact(input: CreatePersonContactInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: CreatePersonContactInput!) {
                    personContact {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updatePersonContact(input: UpdatePersonContactInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdatePersonContactInput!) {
                    personContact {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deletePersonContact(input: DeletePersonContactInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DeletePersonContactInput!) {
                    personContact {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    markPersonContactAsPrimary(input: MarkPersonContactAsPrimaryInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: MarkPersonContactAsPrimaryInput!) {
                    personContact {
                        markAsPrimary(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    unmarkPrimaryPersonContact(input: UnmarkPrimaryPersonContactInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UnmarkPrimaryPersonContactInput!) {
                    personContact {
                        unmarkPrimary(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    activatePersonContact(input: ActivatePersonContactInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: ActivatePersonContactInput!) {
                    personContact {
                        activate(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deactivatePersonContact(input: DeactivatePersonContactInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: DeactivatePersonContactInput!) {
                    personContact {
                        deactivate(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getMedications(input: GridInput | null) {
        return this.query<MedicationGridObject>(
            `
                query($input: GridInput) {
                    medication {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${medicationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getVouchers(input: GridInput | null) {
        return this.query<VoucherGridObject>(
            `
                query($input: GridInput) {
                    voucher {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${voucherGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getVoucher(input: GetVoucherInput) {
        return this.query<VoucherObject>(
            `
                query($input: GetVoucherInput!) {
                    voucher {
                        get(input: $input) {
                            ${voucherFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getCompensations(input: GridInput | null) {
        return this.query<CompensationGridObject>(
            `
                query($input: GridInput) {
                    compensation {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${compensationGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
            {
                fetchPolicy: 'cache-first',
            },
        );
    }

    getDiagnoses(input: GridInput | null) {
        return this.query<DiagnosisGridObject>(
            `
                query($input: GridInput) {
                    diagnosis {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${diagnosisGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createVoucher(input: CreateVoucherInput) {
        return this.mutate<VoucherObject>(
            `
                mutation($input: CreateVoucherInput!) {
                    voucher {
                        create(input: $input) {
                            ${voucherFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateVoucher(input: UpdateVoucherInput) {
        return this.mutate<VoucherObject>(
            `
                mutation($input: UpdateVoucherInput!) {
                    voucher {
                        update(input: $input) {
                            ${voucherFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getVoucherPrescribedMedicalProcedures(input: GridInput) {
        return this.query<VoucherPrescribedMedicalProcedureGridObject>(
            `
                query($input: GridInput) {
                    voucherPrescribedMedicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${voucherPrescribedMedicalProcedureFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createVoucherPrescribedMedicalProcedure(input: VoucherPrescribedMedicalProcedureCreateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: VoucherPrescribedMedicalProcedureCreateInput!) {
                    voucherPrescribedMedicalProcedure {
                        create(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateVoucherPrescribedMedicalProcedure(input: VoucherPrescribedMedicalProcedureUpdateInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: VoucherPrescribedMedicalProcedureUpdateInput!) {
                    voucherPrescribedMedicalProcedure {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteVoucherPrescribedMedicalProcedure(input: VoucherPrescribedMedicalProcedureDeleteInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: VoucherPrescribedMedicalProcedureDeleteInput!) {
                    voucherPrescribedMedicalProcedure {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateInsurances(input: UpdateInsurancesInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdateInsurancesInput!) {
                    voucher {
                        updateInsurances(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFolder(input: GetFolderInput) {
        return this.query<FolderObject>(
            `
                query($input: GetFolderInput!) {
                    folder {
                        get(input: $input) {
                            ${folderFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getFolderPerson(input: GetFolderInput) {
        return this.query<FolderObject>(
            `
                query($input: GetFolderInput!) {
                    folder {
                        get(input: $input) {
                            ${folderPersonFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFolderByPerson(input: GetFolderByPersonInput) {
        return this.query<FolderObject>(
            `
                query($input: GetFolderByPersonInput!) {
                    folder {
                        getByPerson(input: $input) {
                            ${folderFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getFolders(input: GridInput) {
        return this.query<FolderGridObject>(
            `
                query($input: GridInput) {
                    folder {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${folderGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

}
