import { addressFrag } from './common.types';
import { medicalProcedureFrag } from './medical-procedure.types';
import { personFrag } from './person.types';

export const performedProcedureFrag = `
    applications
    billingStatus
    billingReasonForCancellation
    medicalProcedure {
        ${medicalProcedureFrag}
    }
`;

export const performedProcedureGridItemFrag = `
    id
    workVisitId
    code
    name
    dateFrom
    dateTo
    applications
    price
    workId
    insuranceAbbr
    insuranceType
    serviceExpertise
    billingDocumentId
    billingStatus
    billingReasonForCancellation
    documentNumber
    folderId
    personId
    person {
        ${personFrag}
    }
    users {
        id
        name
        surname
        email
        titleBeforeName
        titleAfterName
    }
`;

export const workDataFrag = `
    workData {
        ... on WorkDataVisitObject {
            assistantId
            distanceFrom
            distanceTo
            id
            medication
            note
            state
            visitFrom
            visitTo
            addressFrom {
                ${addressFrag}
            }
            addressTo{
                ${addressFrag}
            }
            voucherNumber
            insuranceAbbr
            contactType
            signed
            locked
            systolicPressure
            diastolicPressure
            pulse
            breath
            glycemia
            spo2
            pain
            weight
            pulseByBeats
            pulseByTouch
        }
        ... on WorkDataUnrealizedVisitObject {
            note
            comment
            voucherNumber
            assistantId
        }
        ... on WorkDataServiceRecordObject {
            note
            comment
        }
        ... on WorkDataExtramuralCareObject {
            note
            comment
            name
            fileIds
        }
    }
`;

export const workFrag = `
    id
    dateFrom
    dateTo
    folderId
    workType
    ${workDataFrag}
    billingStatus
    billingReasonForCancellation
    billingDocumentId
    billingDocumentNumber
    performedProcedures {
        ${performedProcedureFrag}
    }
`;

export const workGridItemFrag = `
    dateFrom
    dateTo
    id
    folderId
    serviceId
    person {
        ${personFrag}
    }
    users {
        id
        name
        surname
        email
        titleAfterName
        titleBeforeName
    }
    workType
    ${workDataFrag}
    billingStatus
    billingReasonForCancellation
    billingDocumentId
    billingDocumentNumber
    performedProcedures {
        ${performedProcedureFrag}
    }
`;

