import { GridInput } from '@it2go/data-grid';
import { FormGridObject } from '@it2go/types';
import { filterFrag } from '../modules/content/service/types/filter.types';
import { formGridItemFrag } from '../modules/content/service/types/form.types';
import { GqlService } from '../modules/content/service/gql.service';
import { Injectable } from '@angular/core';

@Injectable()
export class FormService extends GqlService {
    getForms(input: GridInput | null) {
        return this.query<FormGridObject>(`
            query($input: GridInput) {
                form {
                    filter(input: $input) {
                        ${filterFrag}
                        items {
                            ${formGridItemFrag}
                        }
                    }
                }
            }
        `,
            { input },
        );
    }
}
