import { personContactFrag } from './person.types';

export const voucherSituationFrag = `
    anotherInhabitants
    goal
    inCare
    info
    medication
    mobility
    mobilityDesc
    selfServiceAbility
    selfServiceAbilityDesc
    senseDisability
    senseDisabilityDesc
    contacts {
        ${personContactFrag}
    }
`;

export const voucherFrag = `
    id
    type
    insuranceNumber
    insuranceAbbr
    insuranceType
    insuredSince
    sequenceNumber
    issueDate
    validTo
    closureDate
    doctorId
    primaryDiagnosis {
        code
        name
        sex
        ageFrom
        ageTo
        sign
        selectable
        bonified902
        bonified913
        bonified925
    }
    secondaryDiagnoses {
        reporting
        diagnosis {
            code
            name
            sex
            ageFrom
            ageTo
            sign
            selectable
            bonified902
            bonified913
            bonified925
        }
    }
    compensation {
        code
        description
        priority
    }
    note
    situation {
        ${voucherSituationFrag}
    }
`;

export const voucherGridItemFrag = `
    id
    created
    doctor {
        name
        surname
        titleAfterName
        titleBeforeName
        icpOfDoctor
    }
    insuranceAbbr
    issueDate
    note
    primaryDiagnosis
    sequenceNumber
    validTo
    closureDate
`;

export const voucherNursingCareAssessmentFrag = `
    date
    place
    assessment
    peoplePresent
    assessedBy
`;

export const voucherNursingCareDiagnosisCodeFrag = `
    code
    title
    groupName
    description
    symptoms {
        id
        code
        title
    }
    factors {
        id
        code
        title
    }
    riskFactors {
        id
        code
        title
    }
    goals {
        id
        code
        title
    }
    interventions {
        id
        code
        title
    }
`;

export const voucherNursingCareDiagnosisFrag = `
    code
    title
    groupName
    description
    symptoms {
        id
        code
        title
    }
    factors {
        id
        code
        title
    }
    riskFactors {
        id
        code
        title
    }
    goals {
        id
        code
        title
    }
    interventions {
        id
        code
        title
    }
`;

export const voucherNursingCarePlanFrag = `
    summary
`;
