import { Injectable } from '@angular/core';
import { GridInput } from '@it2go/data-grid';
import {
    CreateWorkInput,
    DeleteWorkInput,
    MedicalProcedureFromActiveVoucherObject,
    MedicalProcedureGridObject,
    MedicalProceduresFromActiveVouchersListInput, OkObject,
    PatchServiceInput,
    ServiceCreateInput,
    ServiceGridObject,
    ServiceObject,
    ServiceTypeGridObject, UpdateWorkInput,
    WorkGridObject, WorkObject,
} from '@it2go/types';
import { GqlService } from '../modules/content/service/gql.service';
import { filterFrag } from './types/filter.types';
import { medicalProcedureFrag, serviceFrag, serviceGridItemFrag, serviceTypeGridItemFrag } from './types/service.types';
import { medicalProcedureFromActiveVoucherFrag } from './types/medical-procedure.types';
import { workGridItemFrag } from './types/work.types';
import { workFrag } from '../modules/content/service/types/work.types';

@Injectable()
export class ServiceService extends GqlService {

    public getServices(input: GridInput | null = null) {
        return this.query<ServiceGridObject>(
            `
                query($input: GridInput) {
                    service {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createService(input: ServiceCreateInput) {
        return this.mutate<ServiceObject>(
            `
                mutation($input: ServiceCreateInput!) {
                    service {
                        create(input: $input) {
                            ${serviceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public patchService(input: PatchServiceInput) {
        return this.mutate<ServiceObject>(
            `
                mutation($input: PatchServiceInput!) {
                    service {
                        patch(input: $input) {
                            ${serviceFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getServiceTypes(input: GridInput | null = null) {
        return this.query<ServiceTypeGridObject>(
            `
                query($input: GridInput) {
                    serviceType {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${serviceTypeGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    public getMedicalProcedures(input: GridInput | null) {
        return this.query<MedicalProcedureGridObject>(
            `
                query($input: GridInput) {
                    medicalProcedure {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${medicalProcedureFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getMedicalProceduresFromActiveVouchers(input: MedicalProceduresFromActiveVouchersListInput) {
        return this.query<MedicalProcedureFromActiveVoucherObject[]>(
            `
                query($input: MedicalProceduresFromActiveVouchersListInput!) {
                    medicalProcedure {
                        listFromActiveVouchers(input: $input) {
                            ${medicalProcedureFromActiveVoucherFrag}
                        }
                    }
                }
            `,
            { input },
        )
    }

    public getWorks(input: GridInput | null) {
        return this.query<WorkGridObject>(`
                query($input: GridInput) {
                    work {
                        filter(input: $input) {
                            ${filterFrag}
                            items {
                                ${workGridItemFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteWork(input: DeleteWorkInput) {
        return this.mutate(
            `
                mutation($input: DeleteWorkInput!) {
                    work {
                        delete(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    public createWork(input: CreateWorkInput) {
        return this.mutate<WorkObject>(`
                mutation($input: CreateWorkInput!) {
                    work {
                        create(input: $input) {
                            ${workFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    public updateWork(input: UpdateWorkInput) {
        return this.mutate<OkObject>(
            `
                mutation($input: UpdateWorkInput!) {
                    work {
                        update(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

}
