import { VoucherNursingCarePlanUpdateInput } from './../../../../types/src/services/input-type/voucher/voucher-nursing-care-plan.update-input';
import { VoucherNursingCarePlanGetInput } from '../../../../types/src/services/input-type/voucher/voucher-nursing-care-plan.get-input';
import { VoucherNursingCareAssessmentCreateInput } from '@libs/types/src/services/input-type/voucher/voucher-nursing-care-assessment.create-input';
import { Injectable } from '@angular/core';
import { GqlService } from '../modules/content/service/gql.service';
import { GridInput } from '@it2go/data-grid';
import {
    VoucherNursingCareAssessmentDeleteInput,
    VoucherNursingCareAssessmentGridItemObject,
    VoucherNursingCareAssessmentGridObject,
    VoucherNursingCareAssessmentUpdateInput,
    VoucherNursingCareDiagnosisCreateInput,
    VoucherNursingCareDiagnosisDeleteInput,
    VoucherNursingCareDiagnosisGridObject,
    VoucherNursingCareDiagnosisUpdateInput,
} from '@it2go/types';
import {
    voucherNursingCareAssessmentFrag,
    voucherNursingCareDiagnosisCodeFrag,
    voucherNursingCareDiagnosisFrag,
    voucherNursingCarePlanFrag,
} from './types/voucher.types';
import { filterFrag } from './types/filter.types';

@Injectable()
export class NursingCareService extends GqlService {
    getDiagnosisCodes(input: GridInput | null) {
        return this.query(
            `
                query($input: GridInput) {
                    voucherNursingCare {
                        filterDiagnosisCodes(input: $input) {
                            ${filterFrag}
                            items {
                                ${voucherNursingCareDiagnosisCodeFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getNursingCarePlan(input: VoucherNursingCarePlanGetInput) {
        return this.query(
            `
                query($input: VoucherNursingCarePlanGetInput!) {
                    voucherNursingCare {
                        getPlan(input: $input) {
                            ${voucherNursingCarePlanFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateNursingCarePlan(input: VoucherNursingCarePlanUpdateInput) {
        return this.mutate(
            `
                mutation($input: VoucherNursingCarePlanUpdateInput!) {
                    voucherNursingCare {
                        updatePlan(input: $input) {
                            ${voucherNursingCarePlanFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    getNursingCareAssessments(input: GridInput | null) {
        return this.query<VoucherNursingCareAssessmentGridObject>(
            `
                query($input: GridInput) {
                    voucherNursingCare {
                        filterAssessments(input: $input) {
                            ${filterFrag}
                            items {
                                ${voucherNursingCareAssessmentFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    getNursingCareDiagnoses(input: GridInput | null) {
        return this.query<VoucherNursingCareDiagnosisGridObject>(
            `
                query($input: GridInput) {
                    voucherNursingCare {
                        filterDiagnoses(input: $input) {
                            ${filterFrag}
                            items {
                                ${voucherNursingCareDiagnosisFrag}
                            }
                        }
                    }
                }
            `,
            { input },
        );
    }

    createNursingCareAssessment(input: VoucherNursingCareAssessmentCreateInput) {
        return this.mutate<VoucherNursingCareAssessmentGridItemObject>(
            `
                mutation($input: VoucherNursingCareAssessmentCreateInput!) {
                    voucherNursingCare {
                        createAssessment(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    createNursingCareDiagnosis(input: VoucherNursingCareDiagnosisCreateInput) {
        return this.mutate<VoucherNursingCareDiagnosisGridObject>(
            `
                mutation($input: VoucherNursingCareDiagnosisCreateInput!) {
                    voucherNursingCare {
                        createDiagnosis(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteNursingCareAssessment(input: VoucherNursingCareAssessmentDeleteInput) {
        return this.mutate(
            `
                mutation($input: VoucherNursingCareAssessmentDeleteInput!) {
                    voucherNursingCare {
                        deleteAssessment(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    deleteNursingCareDiagnosis(input: VoucherNursingCareDiagnosisDeleteInput) {
        return this.mutate(
            `
                mutation($input: VoucherNursingCareDiagnosisDeleteInput!) {
                    voucherNursingCare {
                        deleteDiagnosis(input: $input) {
                            ok
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateNursingCareAssessment(input: VoucherNursingCareAssessmentUpdateInput) {
        return this.mutate(
            `
                mutation($input: VoucherNursingCareAssessmentUpdateInput!) {
                    voucherNursingCare {
                        updateAssessment(input: $input) {
                            ${voucherNursingCareAssessmentFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }

    updateNursingCareDiagnosis(input: VoucherNursingCareDiagnosisUpdateInput) {
        return this.mutate(
            `
                mutation($input: VoucherNursingCareDiagnosisUpdateInput!) {
                    voucherNursingCare {
                        updateDiagnosis(input: $input) {
                            ${voucherNursingCareDiagnosisFrag}
                        }
                    }
                }
            `,
            { input },
        );
    }
}
